import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { gsap, Power2, Elastic } from 'gsap/all'
import Slide1 from "../images/heroSlide/slide1.jpg"
import Slide2 from "../images/heroSlide/slide2.jpg"
import Slide3 from "../images/heroSlide/slide3.jpg"
import Slide4 from "../images/heroSlide/slide4.jpg"
import Slide5 from "../images/heroSlide/slide5.jpg"
import SpSlide1 from "../images/heroSlide/spSlide1.jpg"
import SpSlide2 from "../images/heroSlide/spSlide2.jpg"
import SpSlide3 from "../images/heroSlide/spSlide3.jpg"
import SpSlide4 from "../images/heroSlide/spSlide4.jpg"
import SpSlide5 from "../images/heroSlide/spSlide5.jpg"

import "../scss/components/hero.scss"

let PIXI, MyFilter, OldFilmFilter

if (typeof window !== `undefined`) {
  // viewportUnitsBuggyfill = require('viewport-units-buggyfill');
  PIXI = require('pixi.js')
  MyFilter = require('@pixi/filter-shockwave')
  OldFilmFilter = require('@pixi/filter-old-film')
}

class Hero extends React.Component {
  componentDidMount() {
    PIXI.utils.skipHello()
    // viewportUnitsBuggyfill.init()
    // キャンバスの準備
    const canvas = document.querySelector('.cvs')
    let originalImageWidth
    let originalImageHeight
    let WIDTH = window.innerWidth
    let HEIGHT = window.innerHeight
    let targetWidth
    let targetHeight
    let timer = null

    const app = new PIXI.Application({
      resolution: devicePixelRatio,
      autoResize: true,
      width: WIDTH,
      height: HEIGHT,
      view: canvas
    })

    let mainStage = new PIXI.Container({
      resolution: devicePixelRatio,
      autoResize: true,
      width: WIDTH,
      height: HEIGHT,
    })
    app.stage.addChild(mainStage)

    // 画像準備(SPとPCで切り替え)
    const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    let images
    if (window.navigator.userAgent.search(regexp) !== -1) {
      originalImageWidth = 1000
      originalImageHeight = 1500
      images = [SpSlide1, SpSlide2, SpSlide3, SpSlide4, SpSlide5]
    } else {
      originalImageWidth = 2000
      originalImageHeight = 1333
      images = [Slide1, Slide2, Slide3, Slide4, Slide5]
    }

    // backgroundのcoverみたいにしたいお
    const resizeImage = () => {
      let canvasRatio = HEIGHT / WIDTH
      let imageRatio = originalImageHeight / originalImageWidth

      if (canvasRatio > imageRatio) {
        // canvasのほうが、画像よりも縦長だった場合
        targetHeight = HEIGHT
        targetWidth = HEIGHT * (originalImageWidth / originalImageHeight)
      } else {
        // 画像のほうが、canvasよりも縦長だった場合
        targetWidth = WIDTH
        targetHeight = WIDTH * (originalImageHeight / originalImageWidth)
      }
    }
    resizeImage()

    for (const image of images) {
      const slide = new PIXI.Sprite.from(image)
      slide.width = targetWidth
      slide.height = targetHeight
      slide.anchor.set(0.5)
      slide.position.x = WIDTH / 2
      slide.position.y = HEIGHT / 2
      slide.alpha = 0
      slide.interactive = true
      slide.buttonMode = true

      mainStage.addChild(slide)
    }

    // スライドのアニメーション
    const totalSlide = mainStage.children.length - 1
    let active = 0
    let intervalTime = 3800

    gsap.to(mainStage.children[0], 1, { alpha: 1, ease: Power2.easeOut }, 0)

    const slideAnim = () => {
      let currentSlide
      let nextSlide

      if (active < totalSlide) {
        currentSlide = mainStage.children[active]
        nextSlide = mainStage.children[++active]
      } else {
        currentSlide = mainStage.children[active]
        nextSlide = mainStage.children[0]
        active = 0
      }
      gsap.to(currentSlide, 3, { alpha: 0, ease: Power2.easeOut }, 0)
      gsap.to(nextSlide, 3, { alpha: 1, ease: Power2.easeOut }, 0)

      const oldFilmFilter = new OldFilmFilter.OldFilmFilter({
        sepia: .5,
        noise: .5,
        noiseSize: .5,
        scratch: 1,
        scratchDensity: .5,
        scratchWidth: .1,
        vignetting: 10,
        vignettingAlpha: .5,
        vignettingBlur: 10,
        seed: .2,
      })
      gsap.to(oldFilmFilter, 3, {
        sepia: -0.5,
        noise: 0,
        noiseSize: 0,
        scratch: 0,
        scratchDensity: 0,
        scratchWidth: 0,
        vignetting: 0,
        vignettingAlpha: 0,
        vignettingBlur: 0,
        ease: Power2.easeOut,
        onComplete: () => {
          mainStage.filters.slice(1, 1)
        }
      })
      mainStage.filters = []
      mainStage.filters.push(oldFilmFilter)
    }

    // 時間で発火
    timer = setInterval(slideAnim, intervalTime)

    // クリックしたら次のスライドに行くや〜つ
    const clickAnim = () => {
      slideAnim()
      clearInterval(timer)
      timer = setInterval(slideAnim, intervalTime)
    }

    // うねらせるアニメーション
    const filterAnim = (e) => {
      let centerX = e.data.global.x
      let centerY = e.data.global.y

      const animFilter = new MyFilter.ShockwaveFilter([centerX, centerY], {
        time: 0,
        amplitude: 50,
        wavelength: 100,
        brightness: 1,
        radius: 500,
      })
      gsap.to(animFilter, 2, {
        time: 1,
        ease: Power2.easeOut
      })
      mainStage.filters.push(animFilter)
    }

    // クリック・スマホ時タップした時にスライド＋うねうねを発火したい
    const tapAnim = (e) => {
      clickAnim()
      filterAnim(e)
    }
    for (let i = 0; i < mainStage.children.length; i++) {
      mainStage.children[i].on('pointerdown', tapAnim)
    }

    // リサイズ
    window.addEventListener('resize', () => {
      resizeCanvas()
    })
    const resizeCanvas = () => {
      WIDTH = window.innerWidth
      HEIGHT = window.innerHeight
      app.renderer.resize(WIDTH, HEIGHT)
      resizeImage()
      hero.style.width = WIDTH + `px`
      hero.style.height = HEIGHT + `px`
      for (let i = 0; i < mainStage.children.length; i++) {
        mainStage.children[i].width = targetWidth
        mainStage.children[i].height = targetHeight
        mainStage.children[i].anchor.set(0.5)
        mainStage.children[i].position.x = WIDTH / 2
        mainStage.children[i].position.y = HEIGHT / 2
      }
    }
    const hero = document.querySelector('.hero')
    hero.style.width = WIDTH + `px`
    hero.style.height = HEIGHT + `px`
  }

  render() {
    const heroToggle = () => {
      const heroNav = document.querySelector('.heroNav')
      const heroNavToggle = document.querySelector('.heroNavToggle')
      if (heroNav.classList.contains('active')) {
        heroNav.className = 'heroNav'
        heroNavToggle.className = 'heroNavToggle'
        gsap.to('.heroNav_list-li', .5, { ease: Elastic.easeOut.config(1, 1), stagger: 0.1, x: 150 })
        gsap.to('.heroNavToggle_close', .5, { x: 100, ease: Power2.easeOut })
        gsap.to('.heroNavToggle_menu', .5, { x: 0, ease: Power2.easeOut })

        gsap.to('.heroNavToggle_line-1', .25, {
          rotation: 0, ease: Power2.easeOut, onComplete: () => {
            gsap.to('.heroNavToggle_line-1', .25, { y: 8, ease: Power2.easeOut })
          }
        })
        gsap.to('.heroNavToggle_line-2', .25, { opacity: 1, ease: Power2.easeOut }, .25)
        gsap.to('.heroNavToggle_line-3', .25, {
          rotation: 0, ease: Power2.easeOut, onComplete: () => {
            gsap.to('.heroNavToggle_line-3', .25, { y: -8, ease: Power2.easeOut })
          }
        })
      } else {
        heroNav.className = 'heroNav active'
        heroNavToggle.className = 'heroNavToggle active'
        gsap.to('.heroNav_list-li', .5, { ease: Elastic.easeOut.config(1, 1), stagger: 0.1, x: 0 })
        gsap.to('.heroNavToggle_menu', .5, { x: 100, ease: Power2.easeOut })
        gsap.to('.heroNavToggle_close', .5, { x: 0, ease: Power2.easeOut })

        gsap.to('.heroNavToggle_line-1', .25, {
          y: 0, ease: Power2.easeOut, onComplete: () => {
            gsap.to('.heroNavToggle_line-1', .25, { rotation: 45, ease: Power2.easeOut })
          }
        })
        gsap.to('.heroNavToggle_line-2', .25, { opacity: 0, ease: Power2.easeOut }, .25)
        gsap.to('.heroNavToggle_line-3', .25, {
          y: 0, ease: Power2.easeOut, onComplete: () => {
            gsap.to('.heroNavToggle_line-3', .25, { rotation: -45, ease: Power2.easeOut })
          }
        })
      }
    }

    return (
      <>
        <canvas className="cvs" aria-label="heroPicture"></canvas>
        <div className="hero">
          <h1 className="hero_title">
            <span className="hero_title-s">Web Designer / Developer</span>
            <span className="hero_title-l">
              <span>KENTARO</span>
              <span>KOGA</span>
            </span>
            <span className="hero_title-ss">from Team Fump</span>
          </h1>
          <div className="heroNav">
            <div className="heroNav_bg"></div>
            <ul className="heroNav_list">
              <li className="heroNav_list-li">
                <AniLink fade duration={1} to={`/posts`}>Blog</AniLink>
              </li>
              <li className="heroNav_list-li">
                <AniLink fade duration={1} to={`/profile`}>Profile</AniLink>
              </li>
            </ul>
          </div>
          <button className="heroNavToggle" onClick={heroToggle} aria-label="heroNavToggle">
            <span className="heroNavToggle_line">
              <span className="heroNavToggle_line-1"></span>
              <span className="heroNavToggle_line-2"></span>
              <span className="heroNavToggle_line-3"></span>
            </span>
            <span className="heroNavToggle_menu">menu</span>
            <span className="heroNavToggle_close">close</span>
          </button>
        </div>
      </>
    )
  }
}

export default Hero