import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Loading from "../components/loading"
import Hero from "../components/hero"

const IndexPage = ({location}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            indexOgp
            siteUrl
          }
        }
      }
    `}
    render={data => {
      return(
        <Layout location={location} crumbLabel={"HOME"}>
          <SEO
            title="福岡を拠点にチームで活動しているWebデザイナーです"
            keywords={[`福岡`, `フリーランス`, `エンジニア`, `技術ブログ`]}
            thumbnail={`/${data.site.siteMetadata.indexOgp}`}
          />
          <Loading />
          <Hero />
        </Layout>
      )
    }}
  />
)

export default IndexPage